import {Component} from "react";
import i18n from "i18next";
import EventBus from "../common/EventBus";

export function defaultSuccess(response: any, component: Component, message?: string) {
    component.setState({
        message: message === ""
            ? message
            : response.data
                ? `${message ? message : i18n.t(response.data)}, ${i18n.t("elapsed")} ${getAxiosTimings(response).elapsedTime}ms`
                : `${response}`,
        error: false,
        isSubmitting: false
    });
}

export function defaultError(error: any, component: Component, message?: string) {
    component.setState({
        message: message === ""
            ? message
            : error.response && error.response.data
                ? `${message ? message : i18n.t(error.response.data)}, ${i18n.t("elapsed")} ${getAxiosTimings(error.response).elapsedTime}ms`
                : `${error}`,
        error: true,
        isSubmitting: false
    });
    if (error.response.status === 401 || error.response.status === 403) {
        EventBus.dispatch("logout");
    }
}

export function axiosTime(instance: any) {
    instance.interceptors.request.use(function (request: any) {
        request.timingStart = Date.now();
        return request;
    });
    instance.interceptors.response.use(function (response: any) {
        response.timings = getResponseTimingData(response);
        return response;
    }, function (error: any) {
        if (error.response) {
            error.response.timings = getResponseTimingData(error.response);
        }
        return Promise.reject(error);
    });
}

export function getAxiosTimings(response: any) {
    return response.timings;
}

const getResponseTimingData = (response: any) => {
    const timingStart = response.config.timingStart;
    const timingEnd = Date.now();
    const elapsedTime = calcElapsedTime(timingStart, timingEnd);
    return {
        timingEnd,
        timingStart,
        elapsedTime,
    };
};

const calcElapsedTime = (timingStart: number, timingEnd: number) => {
    if (timingStart && timingEnd) {
        return Math.round(timingEnd - timingStart);
    }
    return;
};