import {Component} from "react";
import {Link} from "react-router-dom";
import i18n from "i18next";

import AppState from "./types/appstate.type";

import {Menu, MenuItem, ProSidebar, SubMenu} from "react-pro-sidebar";
import {FiHome, FiLogIn, FiLogOut, FiSettings, FiUser, FiUsers} from "react-icons/fi";
import {Role} from "./webservice";
import {hasRole} from "./types/user.type";
import EventBus from "./common/EventBus";

type Props = {
    state: AppState,
    handleSidebarToggle: () => boolean
}

export default class Sidebar extends Component<Props> {

    render() {
        var state: AppState = this.props.state;
        return <ProSidebar toggled={state.sidebarToggled} onToggle={this.props.handleSidebarToggle} breakPoint="md">
            {state.currentUser && !hasRole(state.currentUser, Role.UnauthenticatedAdmin) ? (
                <Menu iconShape="square">
                    <MenuItem icon={<FiHome/>}>{i18n.t("Home")} <Link to="/"/></MenuItem>

                    <SubMenu title={i18n.t("User")} icon={<FiUser/>}>
                        <MenuItem>{i18n.t("Dashboard")}<Link to="/user"/></MenuItem>
                        <MenuItem>{i18n.t("Profile")}<Link to="/user/profile"/></MenuItem>
                    </SubMenu>

                    {hasRole(state.currentUser, Role.Admin) && (
                        <SubMenu title={i18n.t("Admin")} icon={<FiSettings/>}>
                            <MenuItem>{i18n.t("Dashboard")}<Link to="/admin"/></MenuItem>
                            <MenuItem>{i18n.t("Administrators")}<Link to="/admin/administrators"/></MenuItem>
                            <MenuItem>{i18n.t("Partners")}<Link to="/admin/partners"/></MenuItem>
                            <MenuItem>{i18n.t("Parameters")}<Link to="/admin/parameters"/></MenuItem>
                        </SubMenu>
                    )}

                    <SubMenu title={i18n.t("Partner")} icon={<FiUsers/>}>
                        <MenuItem>{i18n.t("Dashboard")}<Link to="/partner"/></MenuItem>
                        <MenuItem>{i18n.t("Trip registration")}<Link to="/partner/trip-registration"/></MenuItem>
                    </SubMenu>

                    <MenuItem onClick={() => EventBus.dispatch("logout")}
                              icon={<FiLogOut/>}>{i18n.t("Logout")}</MenuItem>
                </Menu>
            ) : (
                <Menu iconShape="square">
                    <MenuItem icon={<FiHome/>}>{i18n.t("Home")} <Link to="/"/></MenuItem>
                    <MenuItem icon={<FiLogIn/>}>{i18n.t("Login")}<Link to="/login"/></MenuItem>
                    {state.currentUser && (
                        <MenuItem onClick={() => EventBus.dispatch("logout")}
                                  icon={<FiLogOut/>}>{i18n.t("Logout")}</MenuItem>
                    )}
                </Menu>
            )}
        </ProSidebar>
    }
};

