import {Component} from "react";
import {Navigate} from "react-router-dom";
import i18n from "i18next";
import {Field} from "formik";
import FormView, {FormGroup} from "../common/form.component";
import {AdministratorPut, DefaultApi, Parameters} from "../../webservice";
import {getCurrentUser} from "../login.component";
import Header from "../common/header.component";
import {defaultError, defaultSuccess} from "../../utils/general";

type Props = {};

type State = {
    parameters?: Parameters;
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
    administrator?: AdministratorPut;
}

const api: DefaultApi = new DefaultApi();

export default class ParametersView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {};
    }

    componentDidMount() {
        api.parametersGet({
            withCredentials: true
        }).then(
            response => {
                defaultSuccess(response, this, "");
                this.setState({parameters: response.data[0]});
            }
        ).catch(
            error => defaultError(error, this)
        );
    }

    onSubmit(parameters?: any) {
        if (!parameters)
            return;
        if (parameters.passwordNew1
            && parameters.passwordNew1 !== parameters.passwordNew2) {
            this.setState({
                message: i18n.t("Passwords does not match"),
            })
            return;
        }
        this.setState({
            isSubmitting: true
        });
        api.parametersIdPut(
            parameters.par_id,
            parameters,
            {
                withCredentials: true
            }
        ).then(
            response => {
                defaultSuccess(response, this, i18n.t("Updated successfully"));
                this.setState({parameters: response.data});
            }
        ).catch(
            error => defaultError(error, this)
        );
    }

    field(fieldId: string) {
        return (<div key={fieldId} className="form-floating">
            <Field id={fieldId} name={fieldId} className="form-control"/>
            <label htmlFor={fieldId}>{i18n.t(fieldId)}</label>
        </div>);
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;
        if (!this.state.parameters)
            return (
                <div className="component">
                    <Header title={i18n.t("Parameters")}/>
                </div>
            );

        let formGroups: FormGroup[] = [
            {
                id: "first",
                formGroups: [
                    {
                        id: "company_information",
                        title: i18n.t("Company information"),
                        classes: ["col-lg-6", "mb-4"],
                        fields: [
                            "par_tp_namn",
                            "par_tp_gatu_adress",
                            "par_tp_gatu_nr",
                            "par_tp_post_nr",
                            "par_tp_post_adress",
                            "par_tp_besok_adress",
                            "par_epost_tp",
                            "par_tp_org_nr",
                            "par_tp_bank_giro",
                            "par_tp_bank_konto",
                            "par_smtp_server",
                            "par_epost_tp_adm",
                        ]
                    },
                    {
                        id: "right",
                        classes: ["col-lg-6", "mb-4"],
                        formGroups: [
                            {
                                id: "configuration",
                                title: i18n.t("Configuration"),
                                classes: ["mb-4"],
                                fields: [
                                    "par_moms",
                                    "par_skvalp_dag",
                                    "par_skvalp_bel",
                                    "par_rese_max_dag",
                                    "par_hamt_max_dag",
                                    "par_max_faktura_bel",
                                ]
                            },
                            {
                                id: "password",
                                title: i18n.t("Update password"),
                                classes: ["mb-4"],
                                fields: [
                                    "passwordNew1",
                                    "passwordNew2"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: "contact_persons",
                title: i18n.t("Contact persons"),
                classes: ["col", "mb-4"],
                formGroups: [
                    {
                        id: "contact_person1",
                        title: i18n.t("Person") + " 1",
                        classes: ["col-lg-4", "mt-2"],
                        fields: [
                            "par_kt1namn",
                            "par_kt1telefon",
                            "par_kt1mobil",
                            "par_kt1epost",
                        ]
                    },
                    {
                        id: "contact_person2",
                        title: i18n.t("Person") + " 2",
                        classes: ["col-lg-4", "mt-2"],
                        fields: [
                            "par_kt2namn",
                            "par_kt2telefon",
                            "par_kt2mobil",
                            "par_kt2epost",
                        ]
                    },
                    {
                        id: "contact_person3",
                        title: i18n.t("Person") + " 3",
                        classes: ["col-lg-4", "mt-2"],
                        fields: [
                            "par_kt3namn",
                            "par_kt3telefon",
                            "par_kt3mobil",
                            "par_kt3epost",
                        ]
                    }
                ]
            }
        ];

        return (
            <div className="component">
                <Header title={i18n.t("Parameters")}/>
                <div className="content">
                    <FormView initialValues={this.state.parameters} onSubmit={this.onSubmit}
                              formGroups={formGroups} unhandledAsHidden={true}
                              isSubmitting={this.state.isSubmitting}
                              status={this.state.message} error={this.state.error}/>
                </div>
            </div>
        )
    }
}
