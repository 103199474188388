import {Navigate} from "react-router-dom"
import {Component} from "react"
import i18n from "i18next"
import {AdministratorPut, DefaultApi} from "../../webservice"
import {getCurrentUser, latin1SHA256toBase64} from "../login.component";
import Header from "../common/header.component";
import {useParamsInt} from "../../App";
import FormView, {FormGroup} from "../common/form.component";
import {defaultError, defaultSuccess} from "../../utils/general";

type Props = {
    id?: number
};

type State = {
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
    administrator?: AdministratorPut;
}

const api: DefaultApi = new DefaultApi();

function AdministratorView() {
    return (
        <AdministratorComponent id={useParamsInt("id")}/>
    );
}

class AdministratorComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.id === "undefined")
            return;
        if (this.props.id !== 0) {
            this.setState({
                isSubmitting: true
            })
            api.administratorsIdGet(this.props.id, {
                withCredentials: true
            }).then(
                response => {
                    defaultSuccess(response, this, "");
                    let administrator: AdministratorPut = response.data;
                    this.setState({administrator: administrator});
                }
            ).catch(
                error => defaultError(error, this)
            );
        } else {
            this.setState({administrator: getNewAdministrator()});
        }
    }

    onSubmit(data: any) {
        if (!data)
            return;
        if (data.adm_id !== 0) {
            api.administratorsIdPut(
                data.adm_id,
                data, {
                    withCredentials: true
                }
            ).then(
                response => {
                    defaultSuccess(response, this, i18n.t("Updated successfully"));
                    this.setState({administrator: response.data});
                }
            ).catch(
                error => defaultError(error, this)
            );
        } else {
            if (!data.passwordNew1 || data.passwordNew1 !== data.passwordNew2) {
                this.setState({
                    message: i18n.t("Passwords does not match"),
                    error: true
                })
                return;
            }
            data.adm_pass_word = latin1SHA256toBase64(data.passwordNew1);
            delete data.passwordNew1;
            delete data.passwordNew2;

            delete data.adm_id;

            api.administratorsPost(
                data, {
                    withCredentials: true
                }
            ).then(
                response => {
                    defaultSuccess(response, this, i18n.t("Updated successfully"));
                    this.setState({administrator: response.data});
                }
            ).catch(
                error => defaultError(error, this)
            );
        }
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;
        if (this.state.administrator && this.props.id !== this.state.administrator.adm_id)
            return <Navigate to={"/admin/administrators/" + this.state.administrator.adm_id.toString()}/>;
        let formGroups: FormGroup[] = [
            {
                id: "data",
                classes: ["col-12", "col-md-6"],
                fields: ["adm_namn", "adm_status", "adm_mobil_nr", "adm_epost"]
            },
        ];
        if (this.state.administrator && this.state.administrator.adm_id === 0) {
            formGroups.push(
                {
                    id: "password",
                    title: i18n.t("Create password"),
                    classes: ["col-lg-6", "mb-4"],
                    fields: [
                        "passwordNew1",
                        "passwordNew2"
                    ],
                    types: {
                        "passwordNew1": "password",
                        "passwordNew2": "password",
                    }
                });
        }
        return (
            <div className="component">
                <Header titleElement={(<span>{i18n.t("Administrator")}{this.state.administrator && (
                    <strong> {this.state.administrator.adm_namn}</strong>)}</span>)}/>
                <div className="content">
                    {this.state.administrator && (
                        <FormView initialValues={this.state.administrator}
                                  formGroups={formGroups} unhandledAsHidden={true}
                                  onSubmit={this.onSubmit} isSubmitting={this.state.isSubmitting}
                                  status={this.state.message} error={this.state.error}/>
                    )}
                </div>
            </div>
        )
    }
}

function getNewAdministrator(): AdministratorPut {
    return {
        adm_id: 0,
        adm_namn: "",
        adm_pass_word: undefined,
        adm_status: 90,
        adm_mobil_nr: undefined,
        adm_epost: undefined,
        adm_sms_alarm_mottag: undefined,
    }
}

export default AdministratorView;
