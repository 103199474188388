import React from "react";

type Props = {
    title?: string;
    titleElement?: JSX.Element;
    subtitle?: string;
    subtitleElement?: JSX.Element;
}

export default class Header extends React.Component<Props> {
    render() {
        return (
            <header className="p-4 bg-light rounded-3">
                <h1 className="display-5">
                    {this.props.titleElement
                        ? this.props.titleElement
                        : this.props.title
                    }
                </h1>
                {this.props.subtitleElement
                    ? this.props.subtitleElement
                    : this.props.subtitle && (<small>{this.props.subtitle}</small>)
                }
            </header>
        )
    }
}