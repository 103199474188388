import {Component} from "react";
import {Navigate} from "react-router-dom";
import i18n from "i18next";

import {getCurrentUser} from "../login.component";
import Header from "../common/header.component";
import FormView, {FormGroup} from "../common/form.component";
import {DefaultApi, IoFileNew, Role} from "../../webservice";
import {defaultError, defaultSuccess} from "../../utils/general";
import {hasRole} from "../../types/user.type";

type Props = {};

type State = {
    ioFile?: IoFileNew;
    files: { [key: string]: File | null };
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
}

const api: DefaultApi = new DefaultApi();

function getEmptyIoFile(): IoFileNew {
    return {
        iuf_partner_nr: hasRole(getCurrentUser(), Role.Default)
            ? getCurrentUser().id
            : 9999,
        iuf_fil_typ: "Inrapport",
        iuf_info: "Inlämnade resetransaktioner",
        iuf_http_cont_type: "application/octet-stream",
        iuf_data: []
    };
}

async function getAsByteArray(file: Blob): Promise<Uint8Array> {
    return new Uint8Array(await readFile(file))
}

async function readFile(file: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.addEventListener("loadend", e => reader.result instanceof ArrayBuffer
            ? resolve(reader.result)
            : reject
        )
        reader.addEventListener("error", reject)
        reader.readAsArrayBuffer(file)
    })
}

export default class TripRegistration extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            ioFile: getEmptyIoFile(),
            files: {}
        };
        this.onFileSelected = this.onFileSelected.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onFileSelected(fieldId: string, file: File | null) {
        let files = this.state.files;
        files[fieldId] = file;
        this.setState({
            files: files
        });
    }

    onSubmit(data: any) {
        if (!data)
            return;
        if (!this.state.files.iuf_data)
            return;
        getAsByteArray(this.state.files.iuf_data).then((value) => {
            data.iuf_data = Array.from(value);
            api.ioFilesPost(
                data,
                {
                    withCredentials: true
                }
            ).then(
                response => {
                    defaultSuccess(response, this, i18n.t("Updated successfully"));
                    this.setState({ioFile: response.data});
                }
            ).catch(
                error => defaultError(error, this)
            );
        });

    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;

        let adminFormGroups: FormGroup[] = [
            {
                id: "first",
                classes: ["col-lg-6", "mb-4"],
                formGroups: [
                    {
                        id: "partner",
                        fields: [
                            "iuf_partner_nr",
                        ]
                    },
                    {
                        id: "file_upload",
                        fields: [
                            "iuf_data",
                        ],
                        types: {
                            "iuf_data": "file"
                        }
                    },
                ],
            }
        ];
        let formGroups: FormGroup[] = [
            {
                id: "first",
                formGroups: [
                    {
                        id: "file_upload",
                        classes: ["col-lg-6", "mb-4"],
                        fields: [
                            "iuf_data",
                        ],
                        types: {
                            "iuf_data": "file"
                        }
                    },
                ],
            }
        ];

        return (
            <div className="component">
                <Header title={i18n.t("Trip registration")}/>
                <div className="content">
                    {this.state.ioFile && (
                        <FormView initialValues={this.state.ioFile}
                                  formGroups={hasRole(getCurrentUser(), Role.Admin) ? adminFormGroups : formGroups}
                                  unhandledAsHidden={true}
                                  onSubmit={this.onSubmit} isSubmitting={this.state.isSubmitting}
                                  onFileSelected={this.onFileSelected}
                                  status={this.state.message} error={this.state.error}/>
                    )}
                </div>
            </div>
        );
    }
}
