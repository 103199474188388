import {Component} from "react";
import {Navigate} from "react-router-dom";
import i18n from "i18next";

import {getCurrentUser} from "../login.component";
import Header from "../common/header.component";

type Props = {};

type State = {
    message: string;
    error?: boolean;
}

export default class AdminDashboard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: ""
        };
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;
        return (
            <div className="component">
                <Header titleElement={(<span>{i18n.t("Dashboard")} <strong>{i18n.t("Admin")}</strong></span>)}/>
            </div>
        );
    }
}
