import {Navigate} from "react-router-dom"
import {Component} from "react"
import i18n from "i18next"
import {DefaultApi, PartnerPut} from "../../webservice"
import {getCurrentUser, latin1SHA256toBase64} from "../login.component";
import Header from "../common/header.component";
import {useParamsInt} from "../../App";
import FormView, {FormGroup} from "../common/form.component";
import {defaultError, defaultSuccess} from "../../utils/general";

type Props = {
    id?: number
};

type State = {
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
    partner?: PartnerPut;
}

const api: DefaultApi = new DefaultApi();

function PartnerView() {
    return (
        <PartnerComponent id={useParamsInt("id")}/>
    );
}

class PartnerComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.id === "undefined")
            return;
        if (this.props.id !== 0) {
            this.setState({
                isSubmitting: true
            })
            api.partnersIdGet(this.props.id, {
                withCredentials: true
            }).then(
                response => {
                    defaultSuccess(response, this, "");
                    let partner: PartnerPut = response.data;
                    this.setState({partner: partner});
                }
            ).catch(
                error => defaultError(error, this)
            );
        } else {
            this.setState({partner: getNewPartner()});
        }
    }

    onSubmit(data: any) {
        if (!data)
            return;
        if (data.prt_id !== 0) {
            api.partnersIdPut(
                data.prt_id,
                data, {
                    withCredentials: true
                }
            ).then(
                response => {
                    defaultSuccess(response, this, i18n.t("Updated successfully"));
                    this.setState({partner: response.data});
                }
            ).catch(
                error => defaultError(error, this)
            );
        } else {
            if (!data.passwordNew1 || data.passwordNew1 !== data.passwordNew2) {
                this.setState({
                    message: i18n.t("Passwords does not match"),
                    error: true
                })
                return;
            }
            data.prt_pass_word = latin1SHA256toBase64(data.passwordNew1);
            delete data.passwordNew1;
            delete data.passwordNew2;

            delete data.prt_id;

            api.partnersPost(
                data, {
                    withCredentials: true
                }
            ).then(
                response => {
                    defaultSuccess(response, this, i18n.t("Updated successfully"));
                    this.setState({partner: response.data});
                }
            ).catch(
                error => defaultError(error, this)
            );
        }
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;
        if (this.state.partner && this.props.id !== this.state.partner.prt_id)
            return <Navigate to={"/admin/partners/" + this.state.partner.prt_id.toString()}/>;
        let formGroups: FormGroup[] = [
            {
                id: "first",
                formGroups: [
                    {
                        id: "partner_info",
                        title: i18n.t("Partner info"),
                        classes: ["col-lg-6", "mb-4"],
                        fields: [
                            "prt_partner_nr",
                            "prt_namn",
                            "prt_benamn",
                            "prt_gatu_adress",
                            "prt_gatu_nr",
                            "prt_post_nr",
                            "prt_post_adress",
                            "prt_epost",
                            "prt_fax_nr",
                            "prt_taxameter",
                        ]
                    },
                    {
                        id: "accounting_info",
                        title: i18n.t("Accounting info"),
                        classes: ["col-lg-6", "mb-4"],
                        fields: [
                            "prt_org_nr",
                            "prt_kontroll_grans",
                            "prt_bank_konto",
                            "prt_auto_giro",
                            "prt_sftp_aktiv",
                            "prt_ck_redovis_nr",
                            "prt_ax_redovis_nr",
                            "prt_dn_redovis_nr",
                            "prt_bb_redovis_nr",
                            "prt_tb_redovis_nr",
                        ],
                        values: {
                            "prt_auto_giro": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ],
                            "prt_sftp_aktiv": [
                                {key: 1, value: i18n.t("Yes")},
                                {key: 0, value: i18n.t("No")}
                            ],
                        }
                    }
                ],
            },
            {
                id: "second",
                formGroups: [
                    {
                        id: "contract_info",
                        title: i18n.t("Contract info"),
                        classes: ["col-lg-6", "mb-4"],
                        formGroups: [
                            {
                                id: "contact_info",
                                title: i18n.t("Contact"),
                                classes: ["mt-2"],
                                fields: [
                                    "prt_ktk_namn",
                                    "prt_ktk_telefon",
                                    "prt_ktk_epost",
                                ]
                            },
                            {
                                id: "contract_info",
                                title: i18n.t("Contract"),
                                classes: ["mt-2"],
                                fields: [
                                    "prt_ktk_avt_namn",
                                    "prt_ktk_avt_telefon",
                                    "prt_ktk_avt_epost",
                                ]
                            },
                            {
                                id: "contract_status",
                                title: i18n.t("Contract status"),
                                classes: ["mt-2"],
                                fields: [
                                    "kk_avt",
                                    "tp_avt"
                                ],
                                readOnly: true
                            }
                        ],
                    },
                    {
                        id: "configuration",
                        title: i18n.t("Configuration"),
                        classes: ["col-lg-6", "mb-4"],
                        fields: [
                            "prt_utgivare",
                            "prt_utforare",
                            "prt_utgivar_typ",
                            "prt_faktura_typ",
                            "prt_sparr_eflag",
                            "prt_online",
                            "prt_test_prod_flag",
                            "prt_intern",
                            "prt_projekt_nr"
                        ],
                        values: {
                            "prt_utgivare": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ],
                            "prt_utforare": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ],
                            "prt_utgivar_typ": [
                                {key: 1, value: i18n.t("TaxiPass")},
                                {key: 2, value: i18n.t("Credit card")}
                            ],
                            "prt_sparr_eflag": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ],
                            "prt_online": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ],
                            "prt_faktura_typ": [
                                {key: 0, value: i18n.t("Standard")},
                                {key: 1, value: i18n.t("Provision")}
                            ],
                            "prt_test_prod_flag": [
                                {key: "P", value: i18n.t("Production")},
                                {key: "T", value: i18n.t("Test")}
                            ],
                            "prt_intern": [
                                {key: true, value: i18n.t("Yes")},
                                {key: false, value: i18n.t("No")}
                            ]
                        }
                    }
                ],
            }
        ];
        if (this.state.partner && this.state.partner.prt_id === 0) {
            formGroups.push(
                {
                    id: "password",
                    title: i18n.t("Create password"),
                    classes: ["col-lg-6", "mb-4"],
                    fields: [
                        "passwordNew1",
                        "passwordNew2"
                    ],
                    types: {
                        "passwordNew1": "password",
                        "passwordNew2": "password",
                    }
                });
        }

        return (
            <div className="component">
                <Header titleElement={(<span>{i18n.t("Partner")}{this.state.partner && (
                    <strong> {this.state.partner.prt_namn}</strong>)}</span>)}/>
                <div className="content">
                    {this.state.partner && (
                        <FormView initialValues={this.state.partner}
                                  formGroups={formGroups} unhandledAsHidden={true}
                                  onSubmit={this.onSubmit} isSubmitting={this.state.isSubmitting}
                                  status={this.state.message} error={this.state.error}/>
                    )}
                </div>
            </div>
        )
    }
}

function getNewPartner(): PartnerPut {
    return {
        prt_id: 0,
        prt_partner_nr: 0,
        prt_benamn: "",
        prt_namn: "",
        prt_gatu_adress: "",
        prt_gatu_nr: "",
        prt_post_nr: "",
        prt_post_adress: "",
        prt_epost: "",
        prt_utgivar_typ: 1,
        prt_test_prod_flag: "T",
        prt_ck_redovis_nr: "",
        prt_bank_konto: "",
        prt_ax_redovis_nr: "",
        prt_faktulag_nr: 0,
        prt_dn_redovis_nr: "",
        prt_sparr_eflag: false,
        prt_utgivare: false,
        prt_utforare: false,
        prt_ktk_namn: "",
        prt_ktk_telefon: "",
        prt_ktk_epost: "",
        prt_fax_nr: "",
        prt_taxameter: "",
        prt_online: false,
        prt_bb_redovis_nr: "",
        prt_kontroll_grans: 0,
        prt_auto_giro: false,
        prt_ktk_avt_namn: "",
        prt_ktk_avt_telefon: "",
        prt_ktk_avt_epost: "",
        prt_org_nr: "",
        prt_medgiv_datum: undefined,
        prt_medgiv_status: 0,
        prt_sftp_aktiv: 0,
        prt_tb_redovis_nr: "",
        prt_faktura_typ: 0,
        prt_intern: false,
        prt_projekt_nr: ""
    };
}

export default PartnerView;
