import {TableOptions, useSortBy, useTable} from "react-table"
import BTable from "react-bootstrap/Table";
import React from "react";
import {FiArrowDownCircle,FiArrowUpCircle} from "react-icons/fi";

type Options = {
    tableOptions: TableOptions<object>,
    callback: (row: any) => void
};

function onRowClick(callback: (row: any) => void, row : any) : React.MouseEventHandler<HTMLTableRowElement> | undefined {
    if (callback)
        callback(row);
    return undefined;
}

export function Table({tableOptions, callback}: Options) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: tableOptions.columns,
        data: tableOptions.data,
    }, useSortBy)

    // Render the UI for your table
    return (
        <BTable striped bordered hover size="sm" {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted
                                ? (<span> {column.isSortedDesc ? <FiArrowDownCircle/> : <FiArrowUpCircle/>}</span>)
                                : ""}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(callback, row)}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </BTable>
    )
}