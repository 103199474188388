import {Component} from "react";
import {Navigate} from "react-router-dom";
import i18n from "i18next";
import {getCurrentUser, updateAdminPassword} from "../login.component";
import IUser from "../../types/user.type";
import Header from "../common/header.component";
import {defaultError, defaultSuccess} from "../../utils/general";
import FormView from "../common/form.component";

type Props = {};

type State = {
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
}
export default class Profile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.state = {
            message: "",
            error: false,
            isSubmitting: false
        };
    }

    handleChangePassword(formValue: any) {
        if (formValue.passwordNew1 !== formValue.passwordNew2) {
            this.setState({
                message: i18n.t("Passwords does not match"),
                error: true
            })
            return;
        }
        this.setState({
            isSubmitting: true
        });
        updateAdminPassword(formValue.password, formValue.passwordNew2).then(
            request => defaultSuccess(request, this, i18n.t("Updated successfully"))
        ).catch(
            error => defaultError(error, this)
        );
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;

        const currentUser: IUser = getCurrentUser();
        const passwordFormValues = {
            password: "",
            passwordNew1: "",
            passwordNew2: "",
        };

        return (
            <div className="component">
                <div>
                    <Header titleElement={(<span>{i18n.t("Profile")} <strong>{currentUser.username}</strong></span>)}/>

                    <div className="content">
                        <FormView
                            initialValues={currentUser}
                            formGroups={[{
                                id: "user",
                                title: i18n.t("Information"),
                                classes: ["mb-4"],
                                fields: Object.keys(currentUser),
                                readOnly: true
                            }]}/>

                        <FormView
                            initialValues={passwordFormValues}
                            formGroups={[{
                                id: "passwords",
                                title: i18n.t("Update password"),
                                fields: Object.keys(passwordFormValues)
                            }]}
                            onSubmit={this.handleChangePassword}
                            status={this.state.message} error={this.state.error}/>
                    </div>
                </div>
            </div>
        );
    }
}
