/* tslint:disable */
/* eslint-disable */
/**
 * ATP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdministratorCommon
 */
export interface AdministratorCommon {
    /**
     * 
     * @type {string}
     * @memberof AdministratorCommon
     */
    'adm_namn': string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorCommon
     */
    'adm_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorCommon
     */
    'adm_mobil_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorCommon
     */
    'adm_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorCommon
     */
    'adm_sms_alarm_mottag'?: number;
}
/**
 * 
 * @export
 * @interface AdministratorGet
 */
export interface AdministratorGet {
    /**
     * 
     * @type {number}
     * @memberof AdministratorGet
     */
    'adm_id': number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorGet
     */
    'adm_namn': string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorGet
     */
    'adm_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorGet
     */
    'adm_mobil_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorGet
     */
    'adm_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorGet
     */
    'adm_sms_alarm_mottag'?: number;
}
/**
 * 
 * @export
 * @interface AdministratorGetAllOf
 */
export interface AdministratorGetAllOf {
    /**
     * 
     * @type {number}
     * @memberof AdministratorGetAllOf
     */
    'adm_id': number;
}
/**
 * 
 * @export
 * @interface AdministratorPost
 */
export interface AdministratorPost {
    /**
     * 
     * @type {string}
     * @memberof AdministratorPost
     */
    'adm_pass_word': string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPost
     */
    'adm_namn': string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorPost
     */
    'adm_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPost
     */
    'adm_mobil_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPost
     */
    'adm_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorPost
     */
    'adm_sms_alarm_mottag'?: number;
}
/**
 * 
 * @export
 * @interface AdministratorPostAllOf
 */
export interface AdministratorPostAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdministratorPostAllOf
     */
    'adm_pass_word': string;
}
/**
 * 
 * @export
 * @interface AdministratorPut
 */
export interface AdministratorPut {
    /**
     * 
     * @type {number}
     * @memberof AdministratorPut
     */
    'adm_id': number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPut
     */
    'adm_pass_word'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPut
     */
    'adm_namn': string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorPut
     */
    'adm_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPut
     */
    'adm_mobil_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPut
     */
    'adm_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministratorPut
     */
    'adm_sms_alarm_mottag'?: number;
}
/**
 * 
 * @export
 * @interface AdministratorPutAllOf
 */
export interface AdministratorPutAllOf {
    /**
     * 
     * @type {number}
     * @memberof AdministratorPutAllOf
     */
    'adm_id': number;
    /**
     * 
     * @type {string}
     * @memberof AdministratorPutAllOf
     */
    'adm_pass_word'?: string;
}
/**
 * 
 * @export
 * @interface AuthenticatorBody
 */
export interface AuthenticatorBody {
    /**
     * A user name.
     * @type {string}
     * @memberof AuthenticatorBody
     */
    'user': string;
    /**
     * A base64-encoded SHA-256 digest of a user\'s password encoded in Windows 1252 code page.
     * @type {string}
     * @memberof AuthenticatorBody
     */
    'hash': string;
    /**
     * A base64-encoded SHA-256 digest of a user\'s new password encoded in Windows 1252 code page. The new hash will replace the one used for this authentication. 
     * @type {string}
     * @memberof AuthenticatorBody
     */
    'new_hash'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * Count of deleted administrators.
     * @type {number}
     * @memberof InlineResponse200
     */
    'deleted'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * Count of deleted invoices.
     * @type {number}
     * @memberof InlineResponse2001
     */
    'deleted'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * Count of deleted io_files.
     * @type {number}
     * @memberof InlineResponse2002
     */
    'deleted'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * Count of deleted parameters.
     * @type {number}
     * @memberof InlineResponse2003
     */
    'deleted'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * Count of deleted partners.
     * @type {number}
     * @memberof InlineResponse2004
     */
    'deleted'?: number;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_partner_nr': number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_faktura_nr': number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_utgivar_typ': number;
    /**
     * A date
     * @type {string}
     * @memberof Invoice
     */
    'fak_faktura_tid'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_godk_bel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_prov_bel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_moms_prov'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_fulag_bel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_bel'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_bok_jour_nr'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_bet_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'fak_tot_charge_back'?: number;
}
/**
 * 
 * @export
 * @interface IoFile
 */
export interface IoFile {
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_id': number;
    /**
     * The creation timestamp
     * @type {string}
     * @memberof IoFile
     */
    'iuf_datum_tid'?: string;
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_antal_ladd'?: number;
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_data_len'?: number;
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_antal_sftp_ladd'?: number;
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_partner_nr': number;
    /**
     * 
     * @type {string}
     * @memberof IoFile
     */
    'iuf_fil_typ': string;
    /**
     * 
     * @type {string}
     * @memberof IoFile
     */
    'iuf_info'?: string;
    /**
     * 
     * @type {number}
     * @memberof IoFile
     */
    'iuf_antal_post'?: number;
    /**
     * 
     * @type {string}
     * @memberof IoFile
     */
    'iuf_http_cont_type'?: string;
    /**
     * 
     * @type {any}
     * @memberof IoFile
     */
    'iuf_data': any;
}
/**
 * 
 * @export
 * @interface IoFileAllOf
 */
export interface IoFileAllOf {
    /**
     * 
     * @type {number}
     * @memberof IoFileAllOf
     */
    'iuf_id': number;
    /**
     * The creation timestamp
     * @type {string}
     * @memberof IoFileAllOf
     */
    'iuf_datum_tid'?: string;
    /**
     * 
     * @type {number}
     * @memberof IoFileAllOf
     */
    'iuf_antal_ladd'?: number;
    /**
     * 
     * @type {number}
     * @memberof IoFileAllOf
     */
    'iuf_data_len'?: number;
    /**
     * 
     * @type {number}
     * @memberof IoFileAllOf
     */
    'iuf_antal_sftp_ladd'?: number;
}
/**
 * 
 * @export
 * @interface IoFileMeNew
 */
export interface IoFileMeNew {
    /**
     * 
     * @type {string}
     * @memberof IoFileMeNew
     */
    'iuf_fil_typ': string;
    /**
     * 
     * @type {string}
     * @memberof IoFileMeNew
     */
    'iuf_info'?: string;
    /**
     * 
     * @type {number}
     * @memberof IoFileMeNew
     */
    'iuf_antal_post'?: number;
    /**
     * 
     * @type {string}
     * @memberof IoFileMeNew
     */
    'iuf_http_cont_type'?: string;
    /**
     * 
     * @type {any}
     * @memberof IoFileMeNew
     */
    'iuf_data': any;
}
/**
 * 
 * @export
 * @interface IoFileNew
 */
export interface IoFileNew {
    /**
     * 
     * @type {number}
     * @memberof IoFileNew
     */
    'iuf_partner_nr': number;
    /**
     * 
     * @type {string}
     * @memberof IoFileNew
     */
    'iuf_fil_typ': string;
    /**
     * 
     * @type {string}
     * @memberof IoFileNew
     */
    'iuf_info'?: string;
    /**
     * 
     * @type {number}
     * @memberof IoFileNew
     */
    'iuf_antal_post'?: number;
    /**
     * 
     * @type {string}
     * @memberof IoFileNew
     */
    'iuf_http_cont_type'?: string;
    /**
     * 
     * @type {any}
     * @memberof IoFileNew
     */
    'iuf_data': any;
}
/**
 * 
 * @export
 * @interface IoFileNewAllOf
 */
export interface IoFileNewAllOf {
    /**
     * 
     * @type {number}
     * @memberof IoFileNewAllOf
     */
    'iuf_partner_nr': number;
}
/**
 * 
 * @export
 * @interface JournalRequest
 */
export interface JournalRequest {
    /**
     * 
     * @type {number}
     * @memberof JournalRequest
     */
    'journal_no'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JournalRequest
     */
    'inhibit_mail': boolean;
}
/**
 * 
 * @export
 * @interface Parameters
 */
export interface Parameters {
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_id': number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_bas_dir'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_moms'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_amex_fil_nr'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_amex_nxt_sp_vdag'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_amex_nxt_sp_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_cekab_fil_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_cekab_kcv'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_cekab_key'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_bok_jour_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_smtp_server'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_epost_tp_adm'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_epost_tp'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_gatu_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_gatu_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_post_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_post_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_besok_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_org_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_bank_giro'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_bank_konto'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_skvalp_dag'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_skvalp_bel'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_adm_pass_word'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt1namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt1telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt1mobil'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt1epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt2namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt2telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt2mobil'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt2epost'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Parameters
     */
    'par_sparr_tp_flag'?: boolean;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_sparr_tp_tid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Parameters
     */
    'par_sparr_kk_flag'?: boolean;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_sparr_kk_tid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Parameters
     */
    'par_system_test_flag'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_rese_max_dag'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_hamt_max_dag'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_inrapp_beg'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_inrapp_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_bet_uppd_tid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_ck_beg'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_ck_end'?: string;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_ck_fil_tid'?: string;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_ck_hamt_tid'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_sp_ck_int_min'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_bok_jour_tid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_tp_adm_pass_word_r'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_max_faktura_bel'?: number;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_tp_rev_datum'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_sp_tp_upd_tid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Parameters
     */
    'par_skapa_karta'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_babs_fil_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt3namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt3telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt3mobil'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameters
     */
    'par_kt3epost'?: string;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_trace_rad_tid'?: string;
    /**
     * A date
     * @type {string}
     * @memberof Parameters
     */
    'par_last_win_event'?: string;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_control_msg_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof Parameters
     */
    'par_sftp_spara_dagar'?: number;
}
/**
 * 
 * @export
 * @interface PartnerCommon
 */
export interface PartnerCommon {
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_partner_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_benamn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_gatu_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_gatu_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_post_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_post_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_utgivar_typ'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_test_prod_flag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ck_redovis_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_bank_konto'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ax_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_faktulag_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_dn_redovis_nr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_sparr_eflag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_utgivare'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_utforare'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_fax_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_taxameter'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_bb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_kontroll_grans'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_auto_giro'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_avt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_avt_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_ktk_avt_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_org_nr'?: string;
    /**
     * A date
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_medgiv_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_medgiv_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_sftp_aktiv'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_tb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerCommon
     */
    'prt_faktura_typ'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerCommon
     */
    'prt_intern'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerCommon
     */
    'prt_projekt_nr'?: string;
}
/**
 * 
 * @export
 * @interface PartnerGet
 */
export interface PartnerGet {
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_id': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_partner_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_benamn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_gatu_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_gatu_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_post_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_post_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_utgivar_typ'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_test_prod_flag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ck_redovis_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_bank_konto'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ax_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_faktulag_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_dn_redovis_nr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_sparr_eflag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_utgivare'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_utforare'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_fax_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_taxameter'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_bb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_kontroll_grans'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_auto_giro'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_avt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_avt_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_ktk_avt_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_org_nr'?: string;
    /**
     * A date
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_medgiv_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_medgiv_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_sftp_aktiv'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_tb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerGet
     */
    'prt_faktura_typ'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerGet
     */
    'prt_intern'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerGet
     */
    'prt_projekt_nr'?: string;
}
/**
 * 
 * @export
 * @interface PartnerGetAllOf
 */
export interface PartnerGetAllOf {
    /**
     * 
     * @type {number}
     * @memberof PartnerGetAllOf
     */
    'prt_id': number;
}
/**
 * 
 * @export
 * @interface PartnerPost
 */
export interface PartnerPost {
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_pass_word': string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_partner_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_benamn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_gatu_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_gatu_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_post_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_post_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_utgivar_typ'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_test_prod_flag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ck_redovis_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_bank_konto'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ax_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_faktulag_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_dn_redovis_nr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_sparr_eflag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_utgivare'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_utforare'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_fax_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_taxameter'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_bb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_kontroll_grans'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_auto_giro'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_avt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_avt_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_ktk_avt_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_org_nr'?: string;
    /**
     * A date
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_medgiv_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_medgiv_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_sftp_aktiv'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_tb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPost
     */
    'prt_faktura_typ'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPost
     */
    'prt_intern'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPost
     */
    'prt_projekt_nr'?: string;
}
/**
 * 
 * @export
 * @interface PartnerPostAllOf
 */
export interface PartnerPostAllOf {
    /**
     * 
     * @type {string}
     * @memberof PartnerPostAllOf
     */
    'prt_pass_word': string;
}
/**
 * 
 * @export
 * @interface PartnerPut
 */
export interface PartnerPut {
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_id': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_partner_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_benamn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_gatu_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_gatu_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_post_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_post_adress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_epost'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_utgivar_typ'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_test_prod_flag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ck_redovis_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_bank_konto'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ax_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_faktulag_nr'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_dn_redovis_nr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_sparr_eflag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_utgivare'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_utforare'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_fax_nr'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_taxameter'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_bb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_kontroll_grans'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_auto_giro'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_avt_namn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_avt_telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_ktk_avt_epost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_org_nr'?: string;
    /**
     * A date
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_medgiv_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_medgiv_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_sftp_aktiv'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_tb_redovis_nr'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPut
     */
    'prt_faktura_typ'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerPut
     */
    'prt_intern'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerPut
     */
    'prt_projekt_nr'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Role {
    NoRole = 'NoRole',
    Default = 'Default',
    UnauthenticatedAdmin = 'UnauthenticatedAdmin',
    Admin = 'Admin'
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all administrators.
         * @summary Get all administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/administrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the administrator specified by its key.
         * @summary Delete an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administratorsIdDelete', 'id', id)
            const localVarPath = `/administrators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lookup the administrator specified by its key.
         * @summary Lookup an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administratorsIdGet', 'id', id)
            const localVarPath = `/administrators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the administrator specified by its key.
         * @summary Update an administrator
         * @param {number} id 
         * @param {AdministratorPut} [administratorPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdPut: async (id: number, administratorPut?: AdministratorPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administratorsIdPut', 'id', id)
            const localVarPath = `/administrators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(administratorPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a new administrator.
         * @summary Insert a new administrator
         * @param {AdministratorPost} [administratorPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsPost: async (administratorPost?: AdministratorPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/administrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(administratorPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destroy the current session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatorDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authenticate a user and establish a session. If a user\'s password has expired, only establish a session if a new hash replacing the old one is provided. 
         * @summary Login
         * @param {AuthenticatorBody} [authenticatorBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatorPost: async (authenticatorBody?: AuthenticatorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticatorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all invoices.
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the invoice specified by its key.
         * @summary Delete an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypeDelete: async (partnerId: number, invoiceNo: number, publisherType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeDelete', 'partnerId', partnerId)
            // verify required parameter 'invoiceNo' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeDelete', 'invoiceNo', invoiceNo)
            // verify required parameter 'publisherType' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeDelete', 'publisherType', publisherType)
            const localVarPath = `/invoices/{partner_id}/{invoice_no}/{publisher_type}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"invoice_no"}}`, encodeURIComponent(String(invoiceNo)))
                .replace(`{${"publisher_type"}}`, encodeURIComponent(String(publisherType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lookup the invoice specified by its key.
         * @summary Lookup an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypeGet: async (partnerId: number, invoiceNo: number, publisherType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeGet', 'partnerId', partnerId)
            // verify required parameter 'invoiceNo' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeGet', 'invoiceNo', invoiceNo)
            // verify required parameter 'publisherType' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypeGet', 'publisherType', publisherType)
            const localVarPath = `/invoices/{partner_id}/{invoice_no}/{publisher_type}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"invoice_no"}}`, encodeURIComponent(String(invoiceNo)))
                .replace(`{${"publisher_type"}}`, encodeURIComponent(String(publisherType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the invoice specified by its key.
         * @summary Update an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypePut: async (partnerId: number, invoiceNo: number, publisherType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypePut', 'partnerId', partnerId)
            // verify required parameter 'invoiceNo' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypePut', 'invoiceNo', invoiceNo)
            // verify required parameter 'publisherType' is not null or undefined
            assertParamExists('invoicesPartnerIdInvoiceNoPublisherTypePut', 'publisherType', publisherType)
            const localVarPath = `/invoices/{partner_id}/{invoice_no}/{publisher_type}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"invoice_no"}}`, encodeURIComponent(String(invoiceNo)))
                .replace(`{${"publisher_type"}}`, encodeURIComponent(String(publisherType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a new invoice.
         * @summary Insert a new invoice
         * @param {Invoice} [invoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPost: async (invoice?: Invoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all io_files.
         * @summary Get all io_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/io_files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the io_file specified by its key.
         * @summary Delete an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ioFilesIdDelete', 'id', id)
            const localVarPath = `/io_files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lookup the io_file specified by its key.
         * @summary Lookup an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ioFilesIdGet', 'id', id)
            const localVarPath = `/io_files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the io_file specified by its key.
         * @summary Update an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ioFilesIdPut', 'id', id)
            const localVarPath = `/io_files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the io_file\'s, specified by its key, contents.
         * @summary Download an io_file
         * @param {number} iufId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIufIdContentsGet: async (iufId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iufId' is not null or undefined
            assertParamExists('ioFilesIufIdContentsGet', 'iufId', iufId)
            const localVarPath = `/io_files/{iuf_id}/contents`
                .replace(`{${"iuf_id"}}`, encodeURIComponent(String(iufId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a new io_file to the database for later handling.
         * @summary Upload a new io_file
         * @param {IoFileNew} [ioFileNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesPost: async (ioFileNew?: IoFileNew, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/io_files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioFileNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a new partner io_file to the database for later handling.
         * @summary Upload a new partner io_file
         * @param {IoFileMeNew} [ioFileMeNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meIoFilesPost: async (ioFileMeNew?: IoFileMeNew, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/io_files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioFileMeNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all parameters.
         * @summary Get all parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the parameter set specified by its key.
         * @summary Delete a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametersIdDelete', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lookup the parameter set specified by its key.
         * @summary Lookup a a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametersIdGet', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the parameter set specified by its key.
         * @summary Update a parameter set
         * @param {number} id 
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdPut: async (id: number, parameters?: Parameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametersIdPut', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a new parameter set.
         * @summary Insert a new parameter set
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersPost: async (parameters?: Parameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all partners.
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the partner specified by its key.
         * @summary Delete a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnersIdDelete', 'id', id)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lookup the partner specified by its key.
         * @summary Lookup a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnersIdGet', 'id', id)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the partner specified by its key.
         * @summary Update a partner
         * @param {number} id 
         * @param {PartnerPut} [partnerPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdPut: async (id: number, partnerPut?: PartnerPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnersIdPut', 'id', id)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a new partner.
         * @summary Insert a new partner
         * @param {PartnerPost} [partnerPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersPost: async (partnerPost?: PartnerPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all administrators.
         * @summary Get all administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdministratorGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the administrator specified by its key.
         * @summary Delete an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lookup the administrator specified by its key.
         * @summary Lookup an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministratorGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the administrator specified by its key.
         * @summary Update an administrator
         * @param {number} id 
         * @param {AdministratorPut} [administratorPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorsIdPut(id: number, administratorPut?: AdministratorPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministratorGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorsIdPut(id, administratorPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert a new administrator.
         * @summary Insert a new administrator
         * @param {AdministratorPost} [administratorPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorsPost(administratorPost?: AdministratorPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministratorGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorsPost(administratorPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destroy the current session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticatorDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticatorDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authenticate a user and establish a session. If a user\'s password has expired, only establish a session if a new hash replacing the old one is provided. 
         * @summary Login
         * @param {AuthenticatorBody} [authenticatorBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticatorPost(authenticatorBody?: AuthenticatorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticatorPost(authenticatorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all invoices.
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the invoice specified by its key.
         * @summary Delete an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId, invoiceNo, publisherType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lookup the invoice specified by its key.
         * @summary Lookup an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId, invoiceNo, publisherType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the invoice specified by its key.
         * @summary Update an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId, invoiceNo, publisherType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert a new invoice.
         * @summary Insert a new invoice
         * @param {Invoice} [invoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesPost(invoice?: Invoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesPost(invoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all io_files.
         * @summary Get all io_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IoFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the io_file specified by its key.
         * @summary Delete an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lookup the io_file specified by its key.
         * @summary Lookup an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IoFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the io_file specified by its key.
         * @summary Update an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesIdPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IoFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the io_file\'s, specified by its key, contents.
         * @summary Download an io_file
         * @param {number} iufId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesIufIdContentsGet(iufId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesIufIdContentsGet(iufId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a new io_file to the database for later handling.
         * @summary Upload a new io_file
         * @param {IoFileNew} [ioFileNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ioFilesPost(ioFileNew?: IoFileNew, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IoFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ioFilesPost(ioFileNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a new partner io_file to the database for later handling.
         * @summary Upload a new partner io_file
         * @param {IoFileMeNew} [ioFileMeNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meIoFilesPost(ioFileMeNew?: IoFileMeNew, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IoFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meIoFilesPost(ioFileMeNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all parameters.
         * @summary Get all parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parameters>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the parameter set specified by its key.
         * @summary Delete a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lookup the parameter set specified by its key.
         * @summary Lookup a a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the parameter set specified by its key.
         * @summary Update a parameter set
         * @param {number} id 
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametersIdPut(id: number, parameters?: Parameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametersIdPut(id, parameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert a new parameter set.
         * @summary Insert a new parameter set
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametersPost(parameters?: Parameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametersPost(parameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all partners.
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the partner specified by its key.
         * @summary Delete a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lookup the partner specified by its key.
         * @summary Lookup a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the partner specified by its key.
         * @summary Update a partner
         * @param {number} id 
         * @param {PartnerPut} [partnerPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersIdPut(id: number, partnerPut?: PartnerPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersIdPut(id, partnerPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert a new partner.
         * @summary Insert a new partner
         * @param {PartnerPost} [partnerPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnersPost(partnerPost?: PartnerPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnersPost(partnerPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Get all administrators.
         * @summary Get all administrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsGet(options?: any): AxiosPromise<Array<AdministratorGet>> {
            return localVarFp.administratorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the administrator specified by its key.
         * @summary Delete an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdDelete(id: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.administratorsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup the administrator specified by its key.
         * @summary Lookup an administrator
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdGet(id: number, options?: any): AxiosPromise<AdministratorGet> {
            return localVarFp.administratorsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the administrator specified by its key.
         * @summary Update an administrator
         * @param {number} id 
         * @param {AdministratorPut} [administratorPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsIdPut(id: number, administratorPut?: AdministratorPut, options?: any): AxiosPromise<AdministratorGet> {
            return localVarFp.administratorsIdPut(id, administratorPut, options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a new administrator.
         * @summary Insert a new administrator
         * @param {AdministratorPost} [administratorPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorsPost(administratorPost?: AdministratorPost, options?: any): AxiosPromise<AdministratorGet> {
            return localVarFp.administratorsPost(administratorPost, options).then((request) => request(axios, basePath));
        },
        /**
         * Destroy the current session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatorDelete(options?: any): AxiosPromise<string> {
            return localVarFp.authenticatorDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Authenticate a user and establish a session. If a user\'s password has expired, only establish a session if a new hash replacing the old one is provided. 
         * @summary Login
         * @param {AuthenticatorBody} [authenticatorBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatorPost(authenticatorBody?: AuthenticatorBody, options?: any): AxiosPromise<Role> {
            return localVarFp.authenticatorPost(authenticatorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all invoices.
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet(options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.invoicesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the invoice specified by its key.
         * @summary Delete an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId: number, invoiceNo: number, publisherType: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId, invoiceNo, publisherType, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup the invoice specified by its key.
         * @summary Lookup an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId: number, invoiceNo: number, publisherType: number, options?: any): AxiosPromise<Invoice> {
            return localVarFp.invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId, invoiceNo, publisherType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the invoice specified by its key.
         * @summary Update an invoice
         * @param {number} partnerId 
         * @param {number} invoiceNo 
         * @param {number} publisherType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId: number, invoiceNo: number, publisherType: number, options?: any): AxiosPromise<Invoice> {
            return localVarFp.invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId, invoiceNo, publisherType, options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a new invoice.
         * @summary Insert a new invoice
         * @param {Invoice} [invoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesPost(invoice?: Invoice, options?: any): AxiosPromise<Invoice> {
            return localVarFp.invoicesPost(invoice, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all io_files.
         * @summary Get all io_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesGet(options?: any): AxiosPromise<Array<IoFile>> {
            return localVarFp.ioFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the io_file specified by its key.
         * @summary Delete an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdDelete(id: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.ioFilesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup the io_file specified by its key.
         * @summary Lookup an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdGet(id: number, options?: any): AxiosPromise<IoFile> {
            return localVarFp.ioFilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the io_file specified by its key.
         * @summary Update an io_file
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIdPut(id: number, options?: any): AxiosPromise<IoFile> {
            return localVarFp.ioFilesIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the io_file\'s, specified by its key, contents.
         * @summary Download an io_file
         * @param {number} iufId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesIufIdContentsGet(iufId: number, options?: any): AxiosPromise<void> {
            return localVarFp.ioFilesIufIdContentsGet(iufId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a new io_file to the database for later handling.
         * @summary Upload a new io_file
         * @param {IoFileNew} [ioFileNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioFilesPost(ioFileNew?: IoFileNew, options?: any): AxiosPromise<IoFile> {
            return localVarFp.ioFilesPost(ioFileNew, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a new partner io_file to the database for later handling.
         * @summary Upload a new partner io_file
         * @param {IoFileMeNew} [ioFileMeNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meIoFilesPost(ioFileMeNew?: IoFileMeNew, options?: any): AxiosPromise<IoFile> {
            return localVarFp.meIoFilesPost(ioFileMeNew, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all parameters.
         * @summary Get all parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersGet(options?: any): AxiosPromise<Array<Parameters>> {
            return localVarFp.parametersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the parameter set specified by its key.
         * @summary Delete a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdDelete(id: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.parametersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup the parameter set specified by its key.
         * @summary Lookup a a parameter set
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdGet(id: number, options?: any): AxiosPromise<Parameters> {
            return localVarFp.parametersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the parameter set specified by its key.
         * @summary Update a parameter set
         * @param {number} id 
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersIdPut(id: number, parameters?: Parameters, options?: any): AxiosPromise<Parameters> {
            return localVarFp.parametersIdPut(id, parameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a new parameter set.
         * @summary Insert a new parameter set
         * @param {Parameters} [parameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametersPost(parameters?: Parameters, options?: any): AxiosPromise<Parameters> {
            return localVarFp.parametersPost(parameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all partners.
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersGet(options?: any): AxiosPromise<Array<PartnerGet>> {
            return localVarFp.partnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the partner specified by its key.
         * @summary Delete a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdDelete(id: number, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.partnersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup the partner specified by its key.
         * @summary Lookup a partner
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdGet(id: number, options?: any): AxiosPromise<PartnerGet> {
            return localVarFp.partnersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the partner specified by its key.
         * @summary Update a partner
         * @param {number} id 
         * @param {PartnerPut} [partnerPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIdPut(id: number, partnerPut?: PartnerPut, options?: any): AxiosPromise<PartnerGet> {
            return localVarFp.partnersIdPut(id, partnerPut, options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a new partner.
         * @summary Insert a new partner
         * @param {PartnerPost} [partnerPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersPost(partnerPost?: PartnerPost, options?: any): AxiosPromise<PartnerGet> {
            return localVarFp.partnersPost(partnerPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Get all administrators.
     * @summary Get all administrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administratorsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).administratorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the administrator specified by its key.
     * @summary Delete an administrator
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administratorsIdDelete(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).administratorsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lookup the administrator specified by its key.
     * @summary Lookup an administrator
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administratorsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).administratorsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the administrator specified by its key.
     * @summary Update an administrator
     * @param {number} id 
     * @param {AdministratorPut} [administratorPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administratorsIdPut(id: number, administratorPut?: AdministratorPut, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).administratorsIdPut(id, administratorPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a new administrator.
     * @summary Insert a new administrator
     * @param {AdministratorPost} [administratorPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administratorsPost(administratorPost?: AdministratorPost, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).administratorsPost(administratorPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destroy the current session.
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticatorDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticatorDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authenticate a user and establish a session. If a user\'s password has expired, only establish a session if a new hash replacing the old one is provided. 
     * @summary Login
     * @param {AuthenticatorBody} [authenticatorBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticatorPost(authenticatorBody?: AuthenticatorBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticatorPost(authenticatorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all invoices.
     * @summary Get all invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invoicesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invoicesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the invoice specified by its key.
     * @summary Delete an invoice
     * @param {number} partnerId 
     * @param {number} invoiceNo 
     * @param {number} publisherType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invoicesPartnerIdInvoiceNoPublisherTypeDelete(partnerId, invoiceNo, publisherType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lookup the invoice specified by its key.
     * @summary Lookup an invoice
     * @param {number} partnerId 
     * @param {number} invoiceNo 
     * @param {number} publisherType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invoicesPartnerIdInvoiceNoPublisherTypeGet(partnerId, invoiceNo, publisherType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the invoice specified by its key.
     * @summary Update an invoice
     * @param {number} partnerId 
     * @param {number} invoiceNo 
     * @param {number} publisherType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId: number, invoiceNo: number, publisherType: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invoicesPartnerIdInvoiceNoPublisherTypePut(partnerId, invoiceNo, publisherType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a new invoice.
     * @summary Insert a new invoice
     * @param {Invoice} [invoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invoicesPost(invoice?: Invoice, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invoicesPost(invoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all io_files.
     * @summary Get all io_files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the io_file specified by its key.
     * @summary Delete an io_file
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesIdDelete(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lookup the io_file specified by its key.
     * @summary Lookup an io_file
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the io_file specified by its key.
     * @summary Update an io_file
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesIdPut(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the io_file\'s, specified by its key, contents.
     * @summary Download an io_file
     * @param {number} iufId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesIufIdContentsGet(iufId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesIufIdContentsGet(iufId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a new io_file to the database for later handling.
     * @summary Upload a new io_file
     * @param {IoFileNew} [ioFileNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ioFilesPost(ioFileNew?: IoFileNew, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ioFilesPost(ioFileNew, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a new partner io_file to the database for later handling.
     * @summary Upload a new partner io_file
     * @param {IoFileMeNew} [ioFileMeNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meIoFilesPost(ioFileMeNew?: IoFileMeNew, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).meIoFilesPost(ioFileMeNew, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all parameters.
     * @summary Get all parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parametersGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parametersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the parameter set specified by its key.
     * @summary Delete a parameter set
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parametersIdDelete(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parametersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lookup the parameter set specified by its key.
     * @summary Lookup a a parameter set
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parametersIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parametersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the parameter set specified by its key.
     * @summary Update a parameter set
     * @param {number} id 
     * @param {Parameters} [parameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parametersIdPut(id: number, parameters?: Parameters, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parametersIdPut(id, parameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a new parameter set.
     * @summary Insert a new parameter set
     * @param {Parameters} [parameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parametersPost(parameters?: Parameters, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parametersPost(parameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all partners.
     * @summary Get all partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnersGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the partner specified by its key.
     * @summary Delete a partner
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnersIdDelete(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lookup the partner specified by its key.
     * @summary Lookup a partner
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnersIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the partner specified by its key.
     * @summary Update a partner
     * @param {number} id 
     * @param {PartnerPut} [partnerPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnersIdPut(id: number, partnerPut?: PartnerPut, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnersIdPut(id, partnerPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a new partner.
     * @summary Insert a new partner
     * @param {PartnerPost} [partnerPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnersPost(partnerPost?: PartnerPost, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnersPost(partnerPost, options).then((request) => request(this.axios, this.basePath));
    }
}


