import {Role} from "../webservice";

export default interface IUser {
    id?: any | null;
    username?: string | null;
    roles: Array<string>;
    accessToken?: string;
}

export function hasRole(user: IUser, role: Role): boolean {
    if (!user) return false;
    if (!user.roles) return false;
    return user.roles.includes(role);
}