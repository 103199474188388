import React from "react";
import {Field, Form, Formik} from "formik";
import i18n from "i18next";

export type KeyValue = {
    key: any,
    value: string,
}

export type FormGroup = {
    id: string,
    title?: string,
    classes?: string[],
    fields?: string[],
    values?: { [key: string]: KeyValue[] }
    types?: { [key: string]: string }
    hidden?: boolean,
    readOnly?: boolean,
    formGroups?: FormGroup[]
};

type Props = {
    formGroups: FormGroup[],
    unhandledAsHidden?: boolean,
    initialValues: any,
    validationSchema?: any,
    onFileSelected?: (fieldId: string, file: File | null) => void,
    onSubmit?: (data: any) => void,
    isSubmitting?: boolean,
    status?: string,
    error?: boolean
}

export default class FormView extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    field(fieldId: string, initialValues: any, values: any, possibleValues?: KeyValue[], type?: string, readOnly?: boolean) {
        if (values === undefined && initialValues[fieldId] === null)
            initialValues[fieldId] = "";
        if (type === "hidden")
            return <Field key={fieldId} type="hidden" id={fieldId} name={fieldId} className="form-control"/>
        if (type === "file")
            return (
                <div key={fieldId} className="form-group mb-1">
                    <label htmlFor={fieldId}>{i18n.t(fieldId)}</label>
                    <Field type={type} readOnly={readOnly} id={fieldId} name={fieldId} className="form-control"
                           onChange={(e: Event) => {
                               if (!this.props.onFileSelected)
                                   return;
                               const input = e.target as HTMLInputElement;
                               this.props.onFileSelected(fieldId, !input.files?.length ? null : input.files[0]);
                           }}
                    />
                </div>
            );
        if (type)
            return (
                <div key={fieldId} className="form-floating mb-1">
                    <Field type={type} readOnly={readOnly} id={fieldId} name={fieldId}
                           className="form-control"/>
                    <label htmlFor={fieldId}>{i18n.t(fieldId)}</label>
                </div>
            );
        if (possibleValues)
            return (
                <div key={fieldId} className="mb-1">
                    <div role="group" className="form-control py-2" aria-labelledby={fieldId}>
                        <div className="row">
                            <label className="col-4" htmlFor={fieldId}>{i18n.t(fieldId)}</label>
                            <div className="col-8">
                                {possibleValues.map(keyValue => {
                                    return <label key={fieldId + "-" + keyValue.key} className="col-6">
                                        <Field id={fieldId} type={"radio"} name={fieldId}
                                               value={keyValue.key}
                                               checked={
                                                   values[fieldId] !== null && typeof values[fieldId] !== "undefined"
                                                       ? keyValue.key.toString() === values[fieldId].toString()
                                                       : false
                                               }
                                        />
                                        <span className="ms-1">{i18n.t(keyValue.value)}</span>
                                    </label>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        return (
            <div key={fieldId} className="form-floating mb-1">
                <Field readOnly={readOnly} id={fieldId} name={fieldId}
                       className="form-control"/>
                <label htmlFor={fieldId}>{i18n.t(fieldId)}</label>
            </div>
        );
    }

    onSubmit(data: any) {
        let dataClone = Object.assign({}, data);
        this.getFormGroupFields(this.props.formGroups).forEach(fieldId => {
            if (typeof this.props.initialValues[fieldId] === "boolean")
                dataClone[fieldId] = dataClone[fieldId] === "true";
            if (typeof this.props.initialValues[fieldId] === "number")
                dataClone[fieldId] = Number(dataClone[fieldId]);
            if (this.props.initialValues[fieldId] === null && dataClone[fieldId] === "")
                dataClone[fieldId] = null;
        });
        if (this.props.onSubmit)
            this.props.onSubmit(dataClone);
    }

    renderFormGroup(formGroup: FormGroup, level: number, initialValues: any, values: any) {
        const TitleTag = `h${level + 1}` as keyof JSX.IntrinsicElements;
        return (
            <div key={formGroup.id} id={formGroup.id}
                 className={"form-group " + (formGroup.classes ? formGroup.classes.join(" ") : "") + (formGroup.hidden ? "d-none" : "")}>
                {formGroup.title && (<TitleTag>{i18n.t(formGroup.title)}</TitleTag>)}
                {formGroup.fields && formGroup.fields.map(field => this.field(
                    field,
                    initialValues,
                    values,
                    formGroup.values ? formGroup.values[field] : undefined,
                    formGroup.hidden
                        ? "hidden"
                        : formGroup.types && formGroup.types[field]
                            ? formGroup.types[field]
                            : undefined,
                    formGroup.readOnly
                ))}
                {formGroup.formGroups && this.renderFormGroups(
                    formGroup.formGroups,
                    formGroup.title ? level + 1 : level,
                    initialValues, values
                )}
            </div>
        );
    }

    renderFormGroups(formGroups: FormGroup[], level: number, initialValues: any, values: any) {
        if (!formGroups)
            return;
        return (
            <div className="row">
                {formGroups.map(formGroup => this.renderFormGroup(formGroup, level, initialValues, values))}
            </div>
        );
    }

    getFormGroupFields(formGroups: FormGroup[]): string[] {
        let collected: string[] = [];
        formGroups.forEach(formGroup => {
            if (formGroup.fields)
                collected = collected.concat(formGroup.fields);
            if (formGroup.formGroups)
                collected = collected.concat(this.getFormGroupFields(formGroup.formGroups));
        });
        return collected;
    }

    getNonHandledFields(initialValues: any) {
        let formGroupFields: string[] = [];
        if (this.props.formGroups)
            formGroupFields = this.getFormGroupFields(this.props.formGroups);
        let notHandled: string[] = [];
        Object.keys(initialValues).forEach(field => {
            if (!formGroupFields.includes(field))
                notHandled.push(field);
        });
        return notHandled;
    }

    render() {
        if (!this.props.formGroups) return;
        let nonHandledFields: string[] = this.getNonHandledFields(this.props.initialValues);
        return (
            <Formik
                initialValues={this.props.initialValues}
                validationSchema={this.props.validationSchema}
                onSubmit={this.onSubmit}>
                {({values}) => (
                    <Form>
                        {this.renderFormGroups(this.props.formGroups, 1, this.props.initialValues, values)}
                        {this.props.unhandledAsHidden && nonHandledFields && this.renderFormGroups([
                            {
                                id: "non_handled",
                                hidden: true,
                                fields: nonHandledFields
                            }
                        ], 1, this.props.initialValues, values)}
                        {this.props.onSubmit && (
                            <div className="row mt-2">
                                <div className="col-auto">
                                    <button className="btn btn-primary" type="submit"
                                            disabled={this.props.isSubmitting}>
                                        {this.props.isSubmitting && (
                                            <span className={"spinner-border spinner-border-sm me-2"}/>
                                        )}
                                        <span>{i18n.t("Save")}</span>
                                    </button>
                                </div>
                                {this.props.status && this.props.status.length > 0 && (
                                    <div className="col-auto">
                                        <div role="alert"
                                             className={"btn " + (this.props.error ? "alert-danger" : "alert-success")}>
                                            {this.props.status}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        )
    }
}