import {Component} from "react";
import {Link, Route, Routes, useParams, useSearchParams} from "react-router-dom";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations from "./lang/translations";
import globalAxios from "axios";
import {FiMenu} from "react-icons/fi";

import EventBus from "./common/EventBus";
import {axiosTime} from "./utils/general";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import AppState from "./types/appstate.type";
import Sidebar from "./Sidebar";

import Index from "./components/index.component";
import Login, {getCurrentUser, logout} from "./components/login.component";
import AdminDashboard from "./components/admin/dashboard.component";
import AdministratorsView from "./components/admin/administrators.component";
import AdministratorView from "./components/admin/administrator.component";
import PartnersView from "./components/admin/partners.component";
import PartnerView from "./components/admin/partner.component";
import ParametersView from "./components/admin/parameters.component";
import UserDashboard from "./components/user/dashboard.component";
import Profile from "./components/user/profile.component";
import PartnerDashboard from "./components/partner/dashboard.component";
import TripRegistration from "./components/partner/trip-registration.component";

type Props = {
    params: URLSearchParams;
};

export const useParamsInt = (key = "id") => {
    const params = useParams();
    const value: string = params[key] || "";
    return value ? parseInt(value) : undefined;
};

function App() {
    const [searchParams] = useSearchParams();
    return (
        <AppComponent params={searchParams}/>
    );
}

class AppComponent extends Component<Props, AppState> {

    constructor(props: Props) {
        super(props);
        this.logIn = this.logIn.bind(this);
        this.logOut = this.logOut.bind(this);
        this.updateState = this.updateState.bind(this);

        this.state = {
            currentUser: getCurrentUser(),
            sidebarToggled: true
        };
        axiosTime(globalAxios);
    }

    componentDidMount() {
        this.logIn();
        EventBus.on("login", this.logIn)
        EventBus.on("logout", this.logOut);
    }

    componentWillUnmount() {
        EventBus.remove("login", this.logIn);
        EventBus.remove("logout", this.logOut);
    }

    updateState(state: any) {
        this.setState(state);
        EventBus.dispatch("stateChanged", this.state);
    }

    handleSidebarToggle(): boolean {
        this.updateState({
            sidebarToggled: !this.state.sidebarToggled
        })
        return this.state.sidebarToggled;
    }

    logIn() {
        const user = getCurrentUser();
        if (user) {
            this.updateState({
                currentUser: user,
            });
        }
    }

    logOut() {
        logout();
        this.updateState({
            currentUser: undefined,
        })
    }

    render() {
        return (
            <div className={`app  ${this.state.sidebarToggled ? "toggled" : ""}`}>
                <Sidebar state={this.state} handleSidebarToggle={this.handleSidebarToggle.bind(this)}/>
                <main>

                    <nav className="navbar navbar-expand navbar-dark bg-dark">
                        <div className="btn-toggle" onClick={() => this.setState({sidebarToggled: true})}>
                            <FiMenu/>
                        </div>
                        <Link to={"/"} className="navbar-brand">
                            <img className="logo" src={"/logo192.png"} alt={i18n.t("ATP")}/>
                            {i18n.t("ATP")}
                        </Link>
                    </nav>

                    <Routes>
                        <Route path="/" element={<Index/>}/>
                        <Route path="/login" element={<Login params={this.props.params}/>}/>
                        <Route path="/user" element={<UserDashboard/>}/>
                        <Route path="/user/profile" element={<Profile/>}/>
                        <Route path="/admin" element={<AdminDashboard/>}/>
                        <Route path="/admin/administrators" element={<AdministratorsView/>}/>
                        <Route path="/admin/administrators/:id" element={<AdministratorView/>}/>
                        <Route path="/admin/partners" element={<PartnersView/>}/>
                        <Route path="/admin/partners/:id" element={<PartnerView/>}/>
                        <Route path="/admin/parameters" element={<ParametersView/>}/>
                        <Route path="/partner" element={<PartnerDashboard/>}/>
                        <Route path="/partner/trip-registration" element={<TripRegistration/>}/>
                    </Routes>
                </main>
            </div>
        )
    }
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: translations,
        lng: navigator.language || "sv", // if you"re using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default App;