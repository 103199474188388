import {Navigate} from "react-router-dom"
import {Component} from "react"
import i18n from "i18next"
import styled from "styled-components"
import {Table} from "../common/table.component"
import {Column} from "react-table"
import {AdministratorGet, DefaultApi} from "../../webservice"
import {getCurrentUser} from "../login.component";
import Header from "../common/header.component";
import {defaultError, defaultSuccess} from "../../utils/general";

type Props = {};

type State = {
    administrators?: AdministratorGet[];
    administrator?: AdministratorGet;
    message?: string;
    error?: boolean;
    isSubmitting?: boolean;
}

const api: DefaultApi = new DefaultApi();

const Styles = styled.div`
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`

export default class AdministratorsView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
        this.onRowClicked = this.onRowClicked.bind(this);
    }

    componentDidMount() {
        api.administratorsGet({
            withCredentials: true
        }).then(
            response => {
                defaultSuccess(response, this, "")
                this.setState({
                    administrators: response.data.sort((a, b) => a.adm_id - b.adm_id) as AdministratorGet[],
                });
            }
        ).catch(
            error => defaultError(error, this)
        );
    }

    onRowClicked(row: any) {
        this.setState({
            administrator: row.values as AdministratorGet
        })
    }

    adminTable() {
        const columns: Column<object>[] = [
            {
                Header: i18n.t("Name").toString(),
                columns: [
                    {
                        Header: i18n.t("ID").toString(),
                        accessor: "adm_id",
                    },
                    {
                        Header: i18n.t("Name").toString(),
                        accessor: "adm_namn",
                    },
                ],
            },
            {
                Header: i18n.t("Info").toString(),
                columns: [
                    {
                        Header: i18n.t("Status").toString(),
                        accessor: "adm_status",
                    },
                    {
                        Header: i18n.t("Mobile").toString(),
                        accessor: "adm_mobil_nr",
                    },
                    {
                        Header: i18n.t("Email").toString(),
                        accessor: "adm_epost",
                    },
                    {
                        Header: i18n.t("Password changed").toString(),
                        accessor: "adm_pass_word_date",
                    },
                ],
            },
        ];

        const tableOptions = {
            columns: columns,
            data: this.state.administrators || []
        };

        return (
            <Styles>
                <Table tableOptions={tableOptions} callback={this.onRowClicked}/>
            </Styles>
        )
    }

    render() {
        if (!getCurrentUser())
            return <Navigate to={"/login?back=" + window.location.pathname}/>;
        if (this.state.administrator)
            return <Navigate to={this.state.administrator.adm_id.toString()}/>;
        return (
            <div className="component">
                <Header title={i18n.t("Administrators")}/>
                <div className="content">
                    <a className={"btn btn-primary mb-3"} href={"/admin/administrators/0"}>{i18n.t("Create new")}</a>
                    {this.state.administrators && this.adminTable()}
                </div>
            </div>
        )
    }
}
