let translations = {
    en: {
        translation: {
            "ATP": "TaxiPass",
            // Parameters - Company information
            "par_tp_namn": "Name",
            "par_tp_gatu_adress": "Street address/Box",
            "par_tp_gatu_nr": "Street number",
            "par_tp_post_nr": "Postal number",
            "par_tp_post_adress": "City",
            "par_tp_besok_adress": "Visiting address",
            "par_epost_tp": "Email address",
            "par_tp_org_nr": "Organization ID",
            "par_tp_bank_giro": "Bankgiro number",
            "par_tp_bank_konto": "Bank account number",
            "par_smtp_server": "SMTP-server",
            "par_epost_tp_adm": "Email address (admin)",
            // Parameters - Configuration
            "par_moms": "VAT provision (%)",
            "par_skvalp_dag": "Maximum no. of splash days",
            "par_skvalp_bel": "Maximum splash amount",
            "par_rese_max_dag": "Maximum trip age",
            "par_hamt_max_dag": "Maximum fetch age",
            "par_max_faktura_bel": "Maximum invoice amount",
            // Parameters - Update password
            "par_tp_adm_pass_word": "New password",
            "par_tp_adm_pass_word2": "Verify password",
            "passwordNew1": "New password",
            "passwordNew2": "Verify password",
            // Parameters - Contact persons
            "par_kt1namn": "Name",
            "par_kt2namn": "Name",
            "par_kt3namn": "Name",
            "par_kt1telefon": "Phone",
            "par_kt2telefon": "Phone",
            "par_kt3telefon": "Phone",
            "par_kt1mobil": "Mobile",
            "par_kt2mobil": "Mobile",
            "par_kt3mobil": "Mobile",
            "par_kt1epost": "Email address",
            "par_kt2epost": "Email address",
            "par_kt3epost": "Email address", 
            // Admin - Partner
            // Admin - Partner - Partner info
            "prt_partner_nr": "Partner number",
            "prt_namn": "Name",
            "prt_benamn": "Description",
            "prt_gatu_adress": "Street address/Box",
            "prt_gatu_nr": "Street number",
            "prt_post_nr": "Postal number",
            "prt_post_adress": "City",
            "prt_epost": "Email address",
            "prt_fax_nr": "Telefax address",
            "prt_taxameter": "Taxameter",
            // Admin - Partner - Accounting info"
            "prt_org_nr": "Ortanization ID",
            "prt_kontroll_grans": "Control limit",
            "prt_bank_konto": "Bankgiro-/bank account number",
            "prt_auto_giro": "Autogiro",
            "prt_sftp_aktiv": "SFTP-fetch",
            "prt_ck_redovis_nr": "Accounting number Cekab",
            "prt_ax_redovis_nr": "Accounting number Amex",
            "prt_dn_redovis_nr": "Accounting number Diners",
            "prt_bb_redovis_nr": "Accounting number Babs",
            "prt_tb_redovis_nr": "Accounting number Taxibok",
            // Admin - Partner - Contract info
            "prt_ktk_namn": "Name",
            "prt_ktk_avt_namn": "Name",
            "prt_ktk_telefon": "Phone",
            "prt_ktk_avt_telefon": "Phone",
            "prt_ktk_epost": "Email address",
            "prt_ktk_avt_epost": "Email address",
            "kk_avt": "Has KK contract",
            "tp_avt": "Has TP contract",
            // Admin - Partner - Configuration
            "prt_utgivare": "Card issuer",
            "prt_utforare": "Carrier",
            "prt_utgivar_typ": "Card issuer type",
            "prt_faktura_typ": "Invoice type",
            "prt_sparr_eflag": "E-mail block file",
            "prt_online": "Online",
            "prt_test_prod_flag": "Status",
            "prt_intern": "Internal",
            "prt_projekt_nr": "Project No.",
            // Partner
            // Partner - Trip registration
            "iuf_data": "Trip registration file",
            "iuf_partner_nr": "Partner No.",
        }
    },
    sv: {
        translation: {
            // General
            "Admin": "Administratör",
            "Administrator": "Administratör",
            "ATP": "TaxiPass",
            "Authorities": "Behörighet",
            "Create new": "Skapa ny",
            "Credit card": "Kontokort",
            "Configuration": "Konfiguration",
            "Company information": "Företagsinformation",
            "Contact persons": "Kontaktpersoner",
            "Dashboard": "Instrumentpanel",
            "elapsed": "tidsåtgång",
            "Email": "E-postadress",
            "Home": "Hem",
            "Login": "Logga in",
            "Logout": "Logga ut",
            "Mobile": "Mobil",
            "Name": "Namn",
            "No": "Nej",
            "No data found": "Ingen data hittades",
            "Not yet implemented": "Implementation saknas",
            "Password": "Lösenord",
            "Production": "Produktion",
            "Profile": "Profil",
            "Save": "Spara",
            "Submit": "Skicka",
            "This field is required!": "Obligatiskt fält!",
            "Update": "Uppdatera",
            "Updated successfully": "Uppdatering lyckades",
            "User": "Användare",
            "Username": "Användarnamn",
            "Yes": "Ja",
            // General - fields
            "id": "ID",
            "email": "E-postadress",
            "password": "Lösenord",
            "role": "Behörighet",
            "roles": "Behörigheter",
            // Admin
            "Administrators": "Administratörer",
            // Admin - Administrator
            "adm_namn": "Namn",
            "adm_status": "Status",
            "adm_mobil_nr": "Mobil",
            "adm_epost": "E-postadress",
            // Admin - Parameters
            "Parameters": "Parametrar",
            // Admin - Parameters - Company information
            "par_tp_namn": "Namn",
            "par_tp_gatu_adress": "Gatuadress/Box",
            "par_tp_gatu_nr": "Gatunummer",
            "par_tp_post_nr": "Postnummer",
            "par_tp_post_adress": "Postadress/Ort",
            "par_tp_besok_adress": "Besöksadress",
            "par_epost_tp": "E-postadress",
            "par_tp_org_nr": "Organisationsnummer",
            "par_tp_bank_giro": "Bankgironummer",
            "par_tp_bank_konto": "Bankkontonummer",
            "par_smtp_server": "SMTP-server",
            "par_epost_tp_adm": "E-postadress (admin)",
            // Admin - Parameters - Configuration
            "par_moms": "Moms provision (%)",
            "par_skvalp_dag": "Maximalt antal skvalpdagar",
            "par_skvalp_bel": "Maximalt skvalpbelopp",
            "par_rese_max_dag": "Maximal reseålder",
            "par_hamt_max_dag": "Maximal hämtaålder",
            "par_max_faktura_bel": "Maximalt fakturabelopp",
            // Admin - Parameters - Update password
            "par_tp_adm_pass_word": "Nytt lösenord",
            "par_tp_adm_pass_word2": "Verifiera lösenord",
            "passwordNew1": "Nytt lösenord",
            "passwordNew2": "Verifiera lösenord",
            // Admin - Parameters - Contact persons
            "par_kt1namn": "Namn",
            "par_kt2namn": "Namn",
            "par_kt3namn": "Namn",
            "par_kt1telefon": "Telefon",
            "par_kt2telefon": "Telefon",
            "par_kt3telefon": "Telefon",
            "par_kt1mobil": "Mobil",
            "par_kt2mobil": "Mobil",
            "par_kt3mobil": "Mobil",
            "par_kt1epost": "E-postadress",
            "par_kt2epost": "E-postadress",
            "par_kt3epost": "E-postadress",
            // Admin - Partner
            "Partners": "Partner",
            // Admin - Partner - Partner info
            "Partner info": "Partnerinformation",
            "prt_partner_nr": "Partnernummer",
            "prt_namn": "Namn",
            "prt_benamn": "Benämning",
            "prt_gatu_adress": "Gatuadress/Box",
            "prt_gatu_nr": "Gatunummer",
            "prt_post_nr": "Postnummer",
            "prt_post_adress": "Postadress/Ort",
            "prt_epost": "E-postadress",
            "prt_fax_nr": "Telefaxnummer",
            "prt_taxameter": "Taxameter",
            // Admin - Partner - Accounting info"
            "Accounting info": "Redovisningsinformation",
            "prt_org_nr": "Organisationsnummer",
            "prt_kontroll_grans": "Kontrollgräns",
            "prt_bank_konto": "Bankgiro-/bankkontonummer",
            "prt_auto_giro": "Autogiro",
            "prt_sftp_aktiv": "SFTP-hämtning",
            "prt_ck_redovis_nr": "Redovisningsnummer Cekab ",
            "prt_ax_redovis_nr": "Redovisningsnummer Amex",
            "prt_dn_redovis_nr": "Redovisningsnummer Diners",
            "prt_bb_redovis_nr": "Redovisningsnummer Babs",
            "prt_tb_redovis_nr": "Redovisningsnummer Taxibok",
            // Admin - Partner - Contract info
            "Contract info": "Avtalsinformation",
            "Contact": "Kontakt",
            "prt_ktk_namn": "Namn",
            "prt_ktk_avt_namn": "Namn",
            "prt_ktk_telefon": "Telefon",
            "Contract": "Avtal",
            "prt_ktk_avt_telefon": "Telefon",
            "prt_ktk_epost": "E-postadress",
            "prt_ktk_avt_epost": "E-postadress",
            "Contract status": "Avtalsstatus",
            "kk_avt": "Har KK-avtal",
            "tp_avt": "Har TP-avtal",
            // Admin - Partner - Configuration
            "prt_utgivare": "Kortutgivare",
            "prt_utforare": "Transportör",
            "prt_utgivar_typ": "Utgivartyp",
            "prt_faktura_typ": "Fakturatyp",
            "prt_sparr_eflag": "E-post spärrfil",
            "prt_online": "Online",
            "prt_test_prod_flag": "Status",
            "prt_intern": "Intern",
            "prt_projekt_nr": "Projektnummer",
            // Partner
            // Partner - Trip registration
            "Trip registration": "Reseinrapportering",
            "iuf_data": "Inrapportfil",
            "iuf_partner_nr": "Partnernummer",
            // User
            // User - Profile
            "Create password": "Skapa lösenord",
            "Current password": "Nuvarande lösenord",
            "New password": "Nytt lösenord",
            "Password changed": "Lösenord ändrat",
            "Passwords does not match": "Lösenorden är inte identiska",
            "Repeat new password": "Repetera nytt lösenord",
            "Update password": "Byt lösenord",
        }
    }
}
export default translations