import {Component} from "react";
import i18n from "i18next";

import Header from "./common/header.component";

type Props = {};

type State = {
    message: string;
    error?: boolean;
}

export default class Index extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: ""
        };
    }

    render() {
        return (
            <div className="component">
                <Header title={i18n.t("ATP")} subtitle={i18n.t("Home")}/>
            </div>
        );
    }
}
