import {Component} from "react";
import {Navigate, RouteProps} from "react-router-dom";
import i18n from "i18next";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {AdministratorCommon, AuthenticatorBody, DefaultApi, Role} from "../webservice";
import * as Yup from "yup";

import sha256 from "crypto-js/sha256"
import Base64 from "crypto-js/enc-base64"
import Latin1 from "crypto-js/enc-latin1"

import EventBus from "../common/EventBus";
import IUser, {hasRole} from "../types/user.type";

type Props = {
    props?: RouteProps;
    params: URLSearchParams;
};

type State = {
    username: string,
    password: string,
    loading: boolean,
    message: string,
    setNewPassword: boolean
};

let api = new DefaultApi();

export function latin1SHA256toBase64(message: string): string {
    var latin1message = Latin1.parse(message)
    var hash = sha256(latin1message);
    var hash64 = hash.toString(Base64);
    return hash64;
}

export function logout() {
    api.authenticatorDelete({
        withCredentials: true
    });
    localStorage.removeItem("user");
}

export function updateAdminPassword(password: string, newPassword: string): Promise<AdministratorCommon> {
    return new Promise<AdministratorCommon>((resolve) => {
        throw new Error(i18n.t("Not yet implemented"));
    });
    // let api = new DefaultApi();
    // let meHashBody: MeHashBody = {
    //     old: latin1SHA256toBase64(password),
    //     new: latin1SHA256toBase64(newPassword)
    // }
    // return api.meHashPut(meHashBody, {
    //     withCredentials: true
    // }).then(response => {
    //     var body = response.data as AdministratorCommon;
    //     return new Promise((resolve) => {
    //         resolve(body);
    //     });
    // });
}

export function getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
}

export default class Login extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            setNewPassword: false
        };
        EventBus.on("logout", (e) => this.resetState());
    }

    resetState() {
        this.setState({
            username: "",
            password: "",
            loading: false,
            message: "",
            setNewPassword: false
        });
    }

    validationSchema() {
        const requiredText: string = i18n.t("This field is required!");
        return Yup.object().shape({
            username: Yup.string().required(requiredText),
            password: Yup.string().required(requiredText),
        });
    }

    handleLogin(formValue: { username: string; password: string, newPassword: string }) {
        const {username, password, newPassword} = formValue;

        this.setState({
            message: "",
            loading: true
        });

        let authBody: AuthenticatorBody = {
            user: username,
            hash: latin1SHA256toBase64(password),
            new_hash: newPassword ? latin1SHA256toBase64(newPassword) : undefined
        }
        return api.authenticatorPost(authBody, {
            withCredentials: true
        }).then(
            (response) => {
                let role = response.data as Role;
                let user: IUser = {
                    id: username,
                    username: username,
                    roles: [role]
                }
                localStorage.setItem("user", JSON.stringify(user));
                if (user.roles.includes(Role.UnauthenticatedAdmin)) {
                    this.setState({
                        loading: false,
                        message: ""
                    });
                }
                EventBus.dispatch("login");
            }
        ).catch(
            error => {
                if ("CredentialsExpired" === error.response.data) {
                    this.setState({
                        loading: false,
                        message: error.response.data,
                        setNewPassword: true
                    })
                } else {
                    this.setState({
                        loading: false,
                        message: error.response.data
                    });
                }
            }
        );
    }

    render() {
        const {loading, message} = this.state;
        const currentUser: IUser = getCurrentUser();

        const initialValues = {
            username: "",
            password: "",
            newPassword: "",
        };

        return (
            <div className="component">
                <div className="card card-container">
                    <img
                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        alt="profile-img"
                        className="profile-img-card"
                    />

                    {currentUser && !hasRole(currentUser, Role.UnauthenticatedAdmin) && (
                        <Navigate to={this.props.params.get("back") || "/profile"}/>
                    )}

                    {hasRole(currentUser, Role.UnauthenticatedAdmin) && (
                        <b>{i18n.t("Admin area")}</b>
                    )}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleLogin}
                    >
                        <Form>
                            <div className="form-group mt-2">
                                <label htmlFor="username">{i18n.t("Username")}</label>
                                <Field name="username" type="text" className="form-control"/>
                                <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group mt-2">
                                <label htmlFor="password">{i18n.t("Password")}</label>
                                <Field name="password" type="password" className="form-control"/>
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            {this.state.setNewPassword && (
                                <div className="form-group mt-2">
                                    <label htmlFor="newPassword">{i18n.t("New password")}</label>
                                    <Field name="newPassword" type="password" className="form-control"/>
                                    <ErrorMessage
                                        name="newPassword"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                            )}

                            <div className="form-group mt-2">
                                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"/>
                                    )}
                                    <span>{i18n.t("Login")}</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group mt-2">
                                    <div className="alert alert-danger" role="alert">
                                        {i18n.t(message)}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        );
    }
}